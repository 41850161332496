<template>
  <div
    class="d-flex align-items-center w-100 mt-1 mb-1"
    :class="allNotify ? 'pb-1' : 'pt-25 pb-25'"
  >
    <b-skeleton
      type="avatar"
      width="32px"
      height="32px"
      class="mr-1 flex-shrink-0"
      :class="allNotify ? '' : 'ml-25'"
    />
    <div
      v-if="allNotify"
      class="d-flex w-100"
    >
      <div style="width: 90%">
        <b-skeleton
          type="text"
          class="mb-50 w-50"
        />
        <b-skeleton
          type="text"
          class="m-0 w-25"
        />
      </div>
      <div
        style="width: 10%"
        class="my-auto"
      >
        <b-skeleton
          class="mb-0"
          type="text"
        />
      </div>
    </div>
    <div
      v-else
      class="flex-grow-1"
    >
      <b-skeleton
        type="text"
        class="mb-50"
      />
      <b-skeleton
        type="text"
        class="m-0 w-50"
      />
    </div>
  </div>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BSkeleton,
  },
  props: {
    allNotify: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
