<template>
  <div>
    <div class="page-container">
      <h4 class="mb-2">
        Thông báo
      </h4>
      <div class="d-flex justify-content-end mb-2">

      </div>

      <div v-if="isLoading">
        <div class="d-flex justify-content-between vue-good-table-data align-items-center ">
          <b-form-checkbox
            class="mr-1 ml-1 mb-1"
            :indeterminate="isIndeterminate"
            :checked="!!deleteIds.length && deleteIds.length === notifications.length"
            @change="selectAllRows"
          >
            Chọn tất cả
          </b-form-checkbox>
          <div class="d-flex align-items-center">
            <div
              v-if="deleteIds.length"
            >

              <span
                v-b-tooltip.hover.top="'Xóa các mục đã chọn'"
                class="cursor-pointer"
                @click="showModalDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  class="mr-1"
                />
              </span>
              <span
                class="cursor-pointer mr-1"
                @click="readNotifySelected"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Đánh dấu đã đọc các mục đã chọn'"
                  icon="CheckCircleIcon"
                  size="18"
                />
              </span>
            </div>
            <span
              class="cursor-pointer mr-1"
              @click="readAll"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Đánh dấu đã đọc tất cả thông báo'"
                icon="BellOffIcon"
                size="18"
              />
            </span>
            <!-- <b-badge
              class="mr-1 cursor-pointer"
              @click="viewNotification('null')"
            >
              Tất cả
            </b-badge>
            <b-badge
              variant="primary"
              class="mr-1 cursor-pointer"
              @click="viewNotification('Read')"
            >
              Đã đọc
            </b-badge>
            <b-badge
              variant="warning"
              class="mr-1 cursor-pointer"
              @click="viewNotification('Unread')"
            >
              Chưa đọc
            </b-badge> -->
            <b-dropdown
              :text="textDropdown"
              variant="outline-primary"
              class="mr-1"
            >
              <b-dropdown-item @click="viewNotification(null)">
                Tất cả
              </b-dropdown-item>
              <b-dropdown-item @click="viewNotification('Read')">
                Đã đọc
              </b-dropdown-item>
              <b-dropdown-item @click="viewNotification('Unread')">
                Chưa đọc
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div
          v-if="notifications.length > 0"
          class="vue-good-table-data"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="deleteIds"
              :aria-describedby="ariaDescribedby"
              name="flavour-2"
            >
              <div
                v-for="notification in notifications"
                :key="notification.id"
                class="cursor-pointer d-flex flex-column flex-sm-row justify-content-start  justify-content-sm-between align-items-start align-items-sm-center py-1 border-notification pr-1"
                :style="{'background-color': !notification.viewDate ? '#F8F8F8': ''}"
                @click="notificationClick(notification)"
              >
                <div
                  class="d-flex align-items-center"
                  @click.stop
                >
                  <b-form-checkbox
                    class="mr-1 ml-1"
                    :value="notification.id"
                    @click.stop
                  >
                  </b-form-checkbox>
                  <b-media
                    center
                    vertical-align="center"
                    class="d-flex align-items-center"
                    @click="notificationClick(notification)"
                  >

                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="notification.avatar"
                        :text="notification.fullName"
                        variant="primary"
                      />
                    </template>
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                      class=" mb-0"
                      :class=" !notification.viewDate ? 'font-weight-bolder' :'font-weight-normal'"
                    >{{ notification.content }}</p>
                  </b-media>
                </div>
                <p
                  class="text-nowrap mb-0 pl-0 pl-sm-2 mt-1 mt-sm-0"
                  style="color: #797E84"
                  @click="notificationClick(notification)"
                >
                  {{ notification.leftTime }}
                </p>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <div
          v-else
          class="d-flex flex-column justify-content-center align-items-center mt-5"
        >
          <b-img
            width="120px"
            :src="$emptyImg"
            alt="empty table"
          />
          <p class="mt-1 font-weight-bold">
            Không có thông báo
          </p>
        </div>
      </div>
      <div v-else>
        <notification-skeleton allNotify />
        <notification-skeleton allNotify />
        <notification-skeleton allNotify />
        <notification-skeleton allNotify />
        <notification-skeleton allNotify />
        <notification-skeleton allNotify />
      </div>

      <pagination
        class="mb-2"
        :totalItems="totalRecord"
        :pageSize="queryParams.pageSize"
        :currentPage="queryParams.pageNumber"
        :defaultPageSize="queryParams.pageSize"
        @pageClick="pageChange"
      />

      <!-- delete modal  -->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="acceptDelete"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BMedia,
  BAvatar,
  BImg,
  BDropdown,
  BBadge,
  BDropdownItem,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import NotificationSkeleton from '@/views/apps/notifications/NotificationSkeleton.vue'
import Pagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constant/ConstantsApi'

export default {
  components: {
    BFormGroup,
    BMedia,
    BAvatar,
    NotificationSkeleton,
    BImg,
    BDropdown,
    BBadge,
    BDropdownItem,
    Pagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    ConfirmModal,
  },
  directives: {

    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      status: [
        { key: false, value: this.$t('common.unread') },
        { key: true, value: this.$t('common.alreadyRead') },
      ],
      queryParams: {
        notifyStatus: null,
        pageNumber: 1,
        pageSize: 10,
      },
      // textDropdown: 'Tất cả',
      tableColumns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Type',
          field: 'type',
        },
      ],
      deleteIds: [],
      isDelete: false,
      confirmModalId: 'delete-notification',
      modalContent: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('notification', ['notifications', 'totalRecord', 'totalNotificationUnread']),
    isIndeterminate() {
      return !!this.deleteIds.length && this.deleteIds.length < this.notifications.length
    },
    textDropdown() {
      switch (this.queryParams.notifyStatus) {
        case 'Read':
          return 'Đã đọc'
        case 'Unread':
          return 'Chưa đọc'
        default:
          return 'Tất cả'
      }
    },
  },
  created() {
    this.getNotifications()
  },
  methods: {
    ...mapActions('notification', ['handleReadAllNotify', 'getListNotificationViewAll', 'getCountNotificationUnread']),
    ...mapMutations('notification', ['setNotificationUnread', 'setUrlQueryNotifyAll']),

    selectAllRows(val) {
      if (val) {
        this.deleteIds = this.notifications.map(element => element.id)
      } else {
        this.deleteIds = []
      }
    },

    viewNotification(val) {
      if (val) {
        this.queryParams.notifyStatus = val
      } else {
        delete this.queryParams.notifyStatus
      }
      this.queryParams.pageNumber = 1
      this.setUrlQueryNotifyAll(this.queryParams)
      this.getNotifications()
    },

    pageChange(pageNumber, pageSize) {
      this.queryParams.pageNumber = pageNumber
      this.queryParams.pageSize = pageSize
      this.deleteIds = []
      this.getNotifications()
    },
    notificationClick(notification) {
      axiosApiInstance.get(`${ConstantsApi.SEEN_NOTIFICATION}${notification.id}`)
      this.setNotificationUnread(this.totalNotificationUnread - 1)
      this.$router.push(notification.link)
    },
    getTimeLeft(commentDate) {
      moment.locale('vi')
      const time = moment(new Date(commentDate)).fromNow()
      if (time === 'vài giây tới') return 'vài giây trước'
      return time
    },

    async getNotifications() {
      this.isLoading = false
      await this.getListNotificationViewAll(this.queryParams)
      this.notifications.forEach(element => {
        element.leftTime = this.getTimeLeft(element.receiveDate)
      })
      this.isLoading = true
    },

    showModalDelete() {
      this.modalContent = 'Bạn có chắc chắn muốn xóa thông báo này không'
      this.$bvModal.show(this.confirmModalId)
    },

    async acceptDelete() {
      let variant = 'success'
      let message = ''
      const response = await axiosApiInstance.delete(ConstantsApi.DELETE_NOTIFICATION, { data: this.deleteIds })
      if (response.status === 200) {
        this.getNotifications()
        this.deleteIds = []
        message = 'Xóa thành công'
        this.getCountNotificationUnread()
      } else {
        variant = 'danger'
        message = 'Có lỗi xảy ra'
      }
      this.$bvToast.toast(message, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },

    async readAll() {
      let mes
      let variant = 'success'
      const res = await this.handleReadAllNotify()
      if (!res) {
        mes = 'Đánh dấu đã đọc tất cả thành công'
        this.setNotificationUnread()
        this.setUrlQueryNotifyAll(this.queryParams)
        this.getNotifications()
      } else {
        mes = 'Có lỗi xảy ra'
        variant = 'danger'
      }
      this.$bvToast.toast(mes, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },

    async readNotifySelected() {
      let mes
      let variant = 'success'
      try {
        const res = await axiosApiInstance.post(ConstantsApi.CHECK_READ_NOTIFICATION, this.deleteIds)
        if (res.status === 200) {
          mes = 'Đánh dấu đã đọc thành công'
          this.setUrlQueryNotifyAll(this.queryParams)
          this.getNotifications()
          this.deleteIds = []
          this.getCountNotificationUnread()
        }
      } catch (error) {
        mes = 'Có lỗi xảy ra'
        variant = 'danger'
      }
      this.$bvToast.toast(mes, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },

  },
}
</script>
<style lang="scss" scoped>

.vue-good-table-data {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.border-notification {
  border-bottom: 1px solid #CBCDD2 !important;
  border-bottom: 1px solid #CBCDD2 !important;
}
</style>
